import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { triangle, pieChart, statsChart } from 'ionicons/icons';

export default /*@__PURE__*/_defineComponent({
  __name: 'TabsPage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(_unref(IonTabBar), { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                tab: "current",
                href: "/tabs/current"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    "aria-hidden": "true",
                    icon: _unref(triangle)
                  }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Aktuálně")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "charts",
                href: "/tabs/charts"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    "aria-hidden": "true",
                    icon: _unref(pieChart)
                  }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Grafy")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                tab: "statistics",
                href: "/tabs/statistics"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    "aria-hidden": "true",
                    icon: _unref(statsChart)
                  }, null, 8, ["icon"]),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Statistiky")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})